import { useState, useEffect, } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import './Login.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";
import { Helmet } from "react-helmet-async";
import authService from "../../services/auth.service";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import logo_dark from '../../images/logo_white.png';

function Login() {

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [rememberMe, setRememberMe] = useState(false)
    const [loginError, setLoginError] = useState(null)
    const [loginSucces, setLoginSuccess] = useState(false)
    const [isLoggingIn, setIsLoggingIn] = useState(false)

    async function handleLogin(event) {
        setIsLoggingIn(true)
        event.preventDefault()
        let loginDetails = {
            email: email,
            password: password,
            rememberMe: rememberMe
        }

        try {
            const login = await authService.login(loginDetails);

            if (login.email) {
                setLoginError(null)
                setLoginSuccess(true)
                let role = null;

                switch (login.role_id) {
                    case 1:
                        role = 'User'
                        break;
                    case 2:
                        role = 'Administrator'
                        break;
                    default:
                        role = 'Unknown role'
                }

                localStorage.setItem('email', login.email)
                localStorage.setItem('avatar', login.avatar)
                localStorage.setItem('firstname', login.firstname)
                localStorage.setItem('lastname', login.lastname)

                localStorage.setItem('role', role)
                localStorage.setItem('token', login.token)
                localStorage.setItem('xad', true)

                setTimeout(() => {
                    window.location.href = '/dashboard'

                }, 2000)


            } else if (login.message == '2fa') {
                setLoginError(null)
                setLoginSuccess(true)
                console.log('2fa')
                localStorage.setItem('xad', false)
                localStorage.setItem('id', login.id)

                window.location.href = '/login/2fa'
            } else if (login.message == 'email') {
                setLoginError(null)
                setLoginSuccess(true)
                console.log('email not confirmed')
                localStorage.setItem('xad', false)

                window.location.href = '/login/confirmemail'
            } else {
                setLoginError('Uknown Credentials.')
            }
            setIsLoggingIn(false)
            return;

        } catch (err) {
            setLoginError('Uknown Credentials.')
            setIsLoggingIn(false)
            return;

        }


    }

    useEffect(() => {
        let isTokenAlreadySet = localStorage.getItem('token');

        if (isTokenAlreadySet) {
            window.location.href = '/dashboard'
        }

        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const emails = queryParams.get('email');
        const avatars = queryParams.get('avatar');
        const firstname = queryParams.get('firstname');
        const lastname = queryParams.get('lastname');

        if (token && emails && avatars) {
            localStorage.setItem('token', token);
            localStorage.setItem('email', emails);
            localStorage.setItem('avatar', avatars);
            localStorage.setItem('firstname', firstname);
            localStorage.setItem('lastname', lastname);
            localStorage.setItem('xad', true)
            localStorage.setItem('role', 'imposter')

            window.location.href = '/dashboard'

        }
    }, [])

    return (
        <div>
            <Helmet>
                <title>Centnodes | Login to your account</title>
                <meta
                    name="description"
                    content="Login to your account."
                />
            </Helmet>
            <div className="hero-short p-1 ">
                <div className="container mt-5 justify-content-center d-flex">
                    <img src={logo_dark} className="loginLogo img-fluid" alt="company logo dark" />
                </div>
            </div>

            <Container>
                <div className="row d-flex justify-content-center loginRow pb-4">
                    <div className="col-12 col-md-6">
                        <div className="loginBox bg-light border p-2 pb-3 text-center">
                            <h4 className=" h4 color-primary mt-3">Login to your account</h4>
                            <p className="color-gray">Enter your email & password to login.</p>
                            {loginError ? (
                                <Alert className="p-2" variant={'danger'}><span className="text-danger fw-bold">Error: </span> {loginError}</Alert>

                            ) : null}
                            {loginSucces ? (
                                <Alert variant={'success'}><span>Success! Login you in. </span></Alert>

                            ) : null}

                            <Form
                                onSubmit={handleLogin}>
                                <Form.Group className="p-4 text-start d-grid gap-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        id="loginEmail"
                                        onChange={(e) => setEmail(e.target.value)}

                                    />
                                    <Form.Label className="mt-2">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        id="loginPassword"
                                        onChange={(e) => setPassword(e.target.value)}

                                    />
                                    <small><a href="/password/reset-request/">Forgot your password?</a></small>
                                    <Form.Check

                                        className="mt-3"
                                        type='checkbox'
                                        id='loginRememberMe'
                                        label='Remember Me'
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                </Form.Group>
                                <div className="d-grid gap-">
                                    <Button disabled={isLoggingIn} type="submit" id="loginSubmit" className="d-grid btn btn-centhost" onClick={handleLogin}>
                                        Login
                                    </Button>
                                </div>
                            </Form>
                            <ul>
                                <li>
                                    <a href="/register"><FontAwesomeIcon className="mt-5" icon={faUserAlt} /> No account yet? Sign up</a>
                                </li>
                                <li>
                                    <a href="https://centnodes.com"><FontAwesomeIcon className="mt-3" icon={faArrowAltCircleLeft} /> Back to homepage</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container >
        </div>
    )
}
export default Login;