import axios from "axios";
import authService from "./auth.service";

const apiUrl = (url) => {
    let res;
    switch (true) {
        case url.includes('localhost:3000'):
            res = 'http://localhost:8000/api'
            break;
        case url.includes('dev.centnodes.com'):
            res = 'https://api-dev.centnodes.com/api'
            break;
        default:
            res = 'https://api.centnodes.com/api'
            break;
    }
    return res;
}

const API_URL = apiUrl(window.location.href);
const token = localStorage.getItem('token');
const config = { headers: { 'Authorization': 'Bearer ' + token } };

// Fetch all clouds
const getClouds = async () => {
    try {
        const response = await axios.get(API_URL + '/cloud', config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Fetch a specific cloud by ID
const getCloud = async (id) => {
    try {
        const response = await axios.get(API_URL + '/cloud/' + id, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Reboot a cloud
const rebootCloud = async (id) => {
    try {
        const response = await axios.post(API_URL + '/cloud/' + id + '/actions/reboot', {}, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Stop a cloud
const stopCloud = async (id) => {
    try {
        const response = await axios.post(API_URL + '/cloud/' + id + '/actions/stop', {}, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Start a cloud
const startCloud = async (id) => {
    try {
        const response = await axios.post(API_URL + '/cloud/' + id + '/actions/start', {}, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Fetch backups for a specific cloud
const getBackups = async (id) => {
    try {
        const response = await axios.get(API_URL + '/cloud/' + id + '/backups', config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Fetch firewall rules for a specific cloud
const getFirewallRules = async (id) => {
    try {
        const response = await axios.get(API_URL + '/cloud/' + id + '/firewall', config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Add a firewall rule to a specific cloud
const addFirewallRule = async (id, ruleData) => {
    try {
        const response = await axios.post(API_URL + '/cloud/' + id + '/firewall', ruleData, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

// Remove a firewall rule from a specific cloud
const removeFirewallRule = async (id, rulePos) => {
    try {
        const response = await axios.delete(API_URL + '/cloud/' + id + '/firewall/' + rulePos, config);
        return response.data;
    } catch (err) {
        if (err.response.status === 403) {
            return authService.logout();
        }
        throw err;
    }
};

export default {
    stopCloud,
    startCloud,
    rebootCloud,
    getClouds,
    getCloud,
    getBackups,
    getFirewallRules,
    addFirewallRule,
    removeFirewallRule
};
