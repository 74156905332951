import { useEffect, useState } from "react";
import { Col, Row, Card, Tabs, Tab, Form, Alert, Button } from "react-bootstrap";
import './DeployWebhosting.css';
import Navigation from '../../../components/Navigation/Navigation';
import minecraftLogo from '../../../images/design/minecraft-block.png';
import sampLogo from '../../../images/design/samp-logo.png';
import rustLogo from '../../../images/design/rust-logo.png';
import discordLogo from '../../../images/design/discord.png';
import productService from "../../../services/product.service";

export const DeployWebhosting = () => {
    const [packages, setPackages] = useState({
        rust: [],
        minecraft: [],
        samp: [],
        discord: []
    });
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [hostingLabel, setHostingLabel] = useState('');
    const [disableCheckout, setDisableCheckout] = useState(true);
    const [checkOutError, setCheckoutError] = useState(false);

    const getHostingPackages = async () => {
        try {
            const products = await productService.getProductsByType('hosting');
            const productsData = products.data;



            setPackages(productsData);
        } catch (error) {
            console.error("Error fetching hosting packages:", error);
        }
    };


    const selectPackage = (packageId) => {
        const allPackages = Object.values(packages).flat();
        const packageToSelect = allPackages.find(pkg => pkg.id === packageId) || null;

        setSelectedPackage(packageToSelect);
        setDisableCheckout(!packageToSelect);
        setHostingLabel(''); 
    };

    const createOrder = () => {
        if (hostingLabel.length < 4 || !selectedPackage) {
            setCheckoutError(true);
            return;
        }

        setCheckoutError(false);
        const serverDetails = [{
            hostingLabel: hostingLabel,
            productType: 'hosting',
            planId: selectedPackage.id,
            details: null
        }];

        console.log("Order details before saving to localStorage:", serverDetails); // Debug log
        localStorage.setItem('temp_order_details', JSON.stringify(serverDetails));
        window.location.href = '/checkout';
    };

    useEffect(() => {
        getHostingPackages();
    }, []);


    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Deploy hosting</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/hosting">Hosting</a> / Deploy
                </h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h3 className="color-primary mt-2 text-center">Choose package</h3>


                                {packages && packages.length > 0 ? packages.map(pack => (
                                    <div
                                        className={`hostingPackage p-3 ${selectedPackage?.id === pack.id ? 'selectedPackage' : ''}`}
                                        data-key={pack.id}
                                        key={pack.id}
                                        onClick={() => selectPackage(pack.id)}
                                    >
                                        <p>{pack.name}</p>
                                        <p>€ {pack.price}</p>
                                    </div>
                                )) : <p>No packages found.</p>}
                                {selectedPackage && (
                                    <div className="row mt-5">
                                        {checkOutError && <Alert variant="danger" dismissible><span className="fw-bold">Error:</span> Make sure you fill in the required fields.</Alert>}

                                        <Form.Group className="pb-2" controlId="formhostingLabel">
                                            <p>Webhosting Name</p>
                                            <div className="row">
                                                <div className="col-md-6 col-12 pb-3">
                                                    <Form.Control
                                                        type="text"
                                                        value={hostingLabel}
                                                        onChange={(e) => setHostingLabel(e.target.value)}
                                                        maxLength={16}
                                                        placeholder="Enter server name"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Button
                                                        variant="primary"
                                                        disabled={disableCheckout}
                                                        onClick={createOrder}
                                                    >
                                                        Checkout
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
