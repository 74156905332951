import { useEffect, useState } from "react";
import { Col, Row, Card, Tabs, Tab, Form, Alert, Button } from "react-bootstrap";
import './DeployGameServer.css';
import Navigation from '../../../components/Navigation/Navigation';
import minecraftLogo from '../../../images/design/minecraft-block.png';
import sampLogo from '../../../images/design/samp-logo.png';
import rustLogo from '../../../images/design/rust-logo.png';
import discordLogo from '../../../images/design/discord.png';
import productService from "../../../services/product.service";

export const DeployGameServer = () => {
    const [packages, setPackages] = useState({
        rust: [],
        minecraft: [],
        samp: [],
        discord: []
    });
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [serverLabel, setServerLabel] = useState('');
    const [disableCheckout, setDisableCheckout] = useState(true);
    const [checkOutError, setCheckoutError] = useState(false);
    const [selectedTab, setSelectedTab] = useState('minecraft');
    const [formFields, setFormFields] = useState({});

    // Define configurations for each game type with default values
    const gameConfigurations = {
        minecraft: [
            { name: "Variant", type: "select", options: ["Vanilla", "Spigot"], default: "Vanilla" }
        ],
        samp: [
            { name: "Version", type: "string", default: "0.3.7" }
        ],
        rust: [
         
        ],
    };

    // Fetch game server packages
    const getGameServerPackages = async () => {
        try {
            const products = await productService.getProductsByType('gaming');
            const productsData = products.data;

            const productPackagesByGame = {
                minecraft: [],
                samp: [],
                rust: []
            };

            productsData.forEach(product => {
                const { vendor_product_id } = product;
                if (vendor_product_id.includes('minecraft')) {
                    productPackagesByGame.minecraft.push(product);
                } else if (vendor_product_id.includes('samp')) {
                    productPackagesByGame.samp.push(product);
                } else if (vendor_product_id.includes('rust')) {
                    productPackagesByGame.rust.push(product);
                }
            });

            setPackages(productPackagesByGame);
        } catch (error) {
            console.error("Error fetching game server packages:", error);
        }
    };

    // Handle package selection
    const selectPackage = (packageId) => {
        const allPackages = Object.values(packages).flat();
        const packageToSelect = allPackages.find(pkg => pkg.id === packageId) || null;

        setSelectedPackage(packageToSelect);
        setDisableCheckout(!packageToSelect);
        setServerLabel(''); // Reset server label
        setFormFields(prev => {
            // Ensure formFields are reset when a new package is selected
            const config = gameConfigurations[selectedTab] || [];
            const initialFields = config.reduce((acc, field) => {
                acc[field.name] = field.default || '';
                return acc;
            }, {});
            return initialFields; // Reset form fields with default values
        });
    };

    // Create an order
    const createOrder = () => {
        if (serverLabel.length < 4 || !selectedPackage) {
            setCheckoutError(true);
            return;
        }

        setCheckoutError(false);
        const serverDetails = [{
            serverLabel: serverLabel,
            productType: 'gaming',
            planId: selectedPackage.id,
            details: JSON.stringify(getAdditionalDetails()) // Add game-specific details here
        }];

        console.log("Order details before saving to localStorage:", serverDetails); // Debug log
        localStorage.setItem('temp_order_details', JSON.stringify(serverDetails));
        window.location.href = '/checkout';
    };

    // Get additional details based on game type
    const getAdditionalDetails = () => {
        console.log("Form fields being sent:", formFields); // Debug log
        return formFields;
    };

    // Handle form field change
    const handleFieldChange = (name, value) => {
        setFormFields(prev => ({ ...prev, [name]: value }));
    };

    // Update form fields based on selected tab
    useEffect(() => {
        const config = gameConfigurations[selectedTab] || [];
        const initialFields = config.reduce((acc, field) => {
            acc[field.name] = field.default || ''; // Set default value
            return acc;
        }, {});

        console.log("Initializing fields for", selectedTab, ":", initialFields); // Debug log
        setFormFields(initialFields); // Set form fields with default values
    }, [selectedTab]);

    useEffect(() => {
        getGameServerPackages();
    }, []);

    // Render dynamic form fields based on selected game type
    const renderDynamicFields = () => {
        const fields = gameConfigurations[selectedTab] || [];
        return fields.map(field => (
            <Form.Group controlId={`form${field.name.replace(/\s+/g, '')}`} key={field.name}>
                <Form.Label>{field.name}</Form.Label>
                {field.type === 'select' ? (
                    <Form.Control
                        as="select"
                        value={formFields[field.name] || ''}
                        onChange={(e) => handleFieldChange(field.name, e.target.value)}
                    >
                        {field.options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                ) : (
                    <Form.Control
                        type={field.type}
                        value={formFields[field.name] || ''}
                        onChange={(e) => handleFieldChange(field.name, e.target.value)}
                        placeholder={`Enter ${field.name.toLowerCase()}`}
                    />
                )}
            </Form.Group>
        ));
    };

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Deploy game server</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/gaming">Gaming</a> / Deploy
                </h6>
                <Row className="mt-4">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <h3 className="color-primary mt-2 text-center">Choose game</h3>

                                <Tabs
                                    activeKey={selectedTab}
                                    onSelect={(k) => setSelectedTab(k)}
                                    id="gameServerSelector"
                                    className="mb-3"
                                    justify
                                >
                                    <Tab eventKey="minecraft" title={<><img src={minecraftLogo} className="img-fluid" /><p>Minecraft</p></>}>
                                        {packages.minecraft.map(pack => (
                                            <div
                                                className={`gameServerPackage p-3 ${selectedPackage?.id === pack.id ? 'selectedPackage' : ''}`}
                                                data-key={pack.id}
                                                key={pack.id}
                                                onClick={() => selectPackage(pack.id)}
                                            >
                                                <p>{pack.name}</p>
                                                <p>€ {pack.price}</p>
                                            </div>
                                        ))}
                                        {renderDynamicFields()}
                                    </Tab>
                                    <Tab eventKey="samp" title={<><img src={sampLogo} className="img-fluid" /><p>SA-MP</p></>}>
                                        {packages.samp.map(pack => (
                                            <div
                                                className={`gameServerPackage p-3 ${selectedPackage?.id === pack.id ? 'selectedPackage' : ''}`}
                                                data-key={pack.id}
                                                key={pack.id}
                                                onClick={() => selectPackage(pack.id)}
                                            >
                                                <p>{pack.name}</p>
                                                <p>€ {pack.price}</p>
                                            </div>
                                        ))}
                                        {renderDynamicFields()}
                                    </Tab>
                                    {/* <Tab eventKey="rust" title={<><img src={rustLogo} className="img-fluid" /><p>rust</p></>}>
                                        {packages.rust.map(pack => (
                                            <div
                                                className={`gameServerPackage p-3 ${selectedPackage?.id === pack.id ? 'selectedPackage' : ''}`}
                                                data-key={pack.id}
                                                key={pack.id}
                                                onClick={() => selectPackage(pack.id)}
                                            >
                                                <p>{pack.name}</p>
                                                <p>€ {pack.price}</p>
                                            </div>
                                        ))}
                                        {renderDynamicFields()}
                                    </Tab> */}
                                </Tabs>

                                {selectedPackage && (
                                    <div className="row mt-5">
                                        {checkOutError && <Alert variant="danger" dismissible><span className="fw-bold">Error:</span> Make sure you fill in the required fields.</Alert>}

                                        <Form.Group className="pb-2" controlId="formServerLabel">
                                            <p className="m-0">Server Name</p>
                                            <small>This is a server name for on the panel, this is not how your server is displayed to the public.</small>
                                            <div className="row">
                                                <div className="col-md-6 col-12 pb-3">
                                                    <Form.Control
                                                        type="text"
                                                        value={serverLabel}
                                                        onChange={(e) => setServerLabel(e.target.value)}
                                                        placeholder="Enter server name"
                                                        maxLength={16}
                                                    />
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Button
                                                        variant="primary"
                                                        disabled={disableCheckout}
                                                        onClick={createOrder}
                                                    >
                                                        Checkout
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
