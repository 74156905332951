import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowsRotate, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import './Cloud.css';
import Navigation from '../../../components/Navigation/Navigation';
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal.js";
import OSimage from "../../../components/OSimage/OSimage";
import RegionFlag from "../../../components/RegionFlag/RegionFlag";
import { Spinner, Alert } from "react-bootstrap";
import cloudService from "../../../services/cloud.service.js";

export const Cloud = () => {
    const [cloud, setCloud] = useState(null);
    const [vmInfo, setVmInfo] = useState(null);
    const [backupBlock, setBackupBlock] = useState(true);
    const [cloudDetailsBlock, setCloudDetailsBlock] = useState(false);
    const [fireWallBlock, setFirewallBlock] = useState(true);
    const [recoveryBlock, setRecoveryBlock] = useState(true);
    const [destroyBlock, setDestroyBlock] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(null);

    const getCloud = async (instanceId) => {
        try {
            const response = await cloudService.getCloud(instanceId);
            setCloud(response.data.cloud);
            setVmInfo(response.data.vm_info); // Set VM info from Proxmox
            setLoading(false);
        } catch (error) {
            console.error("Error fetching cloud details:", error);
            setLoading(false);
        }
    };

    const menuChange = (item) => {
        setBackupBlock(true);
        setCloudDetailsBlock(true);
        setFirewallBlock(true);
        setRecoveryBlock(true);
        setDestroyBlock(true);

        switch (item) {
            case 'backups':
                setBackupBlock(false);
                break;
            case 'firewall':
                setFirewallBlock(false);
                break;
            case 'recovery':
                setRecoveryBlock(false);
                break;
            case 'destroy':
                setDestroyBlock(false);
                break;
            case 'default':
                setCloudDetailsBlock(false);
                break;
        }
    };

    const hideConfirmationBox = () => {
        setShowConfirmation(false);
        setActionType(null);
    };

    const instanceAction = async (action, confirmed) => {
        if (!confirmed) {
            setShowConfirmation(true);
            setActionType(action);
            return;
        }

        try {
            let actionResponse;
            switch (action) {
                case 'start':
                    actionResponse = await cloudService.startCloud(cloud.id);
                    break;
                case 'stop':
                    actionResponse = await cloudService.stopCloud(cloud.id);
                    break;
                case 'reboot':
                    actionResponse = await cloudService.rebootCloud(cloud.id);
                    break;
                default:
                    throw new Error("Invalid action type");
            }
            hideConfirmationBox();
            setSuccessMessage(`Action ${action} successful.`);
            setTimeout(() => setSuccessMessage(null), 3000);
            setTimeout(() => getCloud(cloud.id), 500);
        } catch (error) {
            console.error(`Error performing action ${action}:`, error);
        }
    };

    useEffect(() => {
        const instanceId = window.location.href.split("/cloud").pop().replace('/', '');
        getCloud(instanceId);
    }, []);

    return (
        <div className="content">
            <Navigation />
            <div id="main" className="px-3 mt-5">
                <h4 className="mt-3 page-title">Server Details</h4>
                <h6 className="mt-1 breadcrumb">
                    <a className="breadcrumb" href="/dashboard">Dashboard</a> /
                    <a className="breadcrumb" href="/products/clouds"> Servers</a> /
                    {cloud && cloud.label.replace('centnodes-', '')}
                </h6>

                {successMessage && (
                    <Alert variant="success" className="mt-3">
                        {successMessage}
                    </Alert>
                )}

                {loading ? (
                    <div className="text-center p-3">
                        <Spinner animation="border" variant="primary" />
                        <h6 className="text-center">Getting cloud details</h6>
                    </div>
                ) : (
                    <div className="row mt-3 gy-3">
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="p-3 serverStatBox">
                                <OSimage osId={cloud.os_id} height={'75px'} />
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="p-3 serverStatBox">
                                <h3 className="text-white">Region:</h3>
                                {/* <RegionFlag regionId={cloud.region} height={'55px'} /> */}
                                <p>{cloud.region}</p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="p-3 serverStatBox ">
                                <ul className="list-unstyled ">
                                    <li className="text-white">Status: <span className={`text-${cloud.status === 'active' ? 'success' : 'danger'}`}>{cloud.status}</span></li>
                                    <li className="text-white">IP: {cloud.ip}</li>
                                    <li className="text-white">Hostname: {cloud.hostname}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="p-3 serverStatBox">
                                <div className="d-grid gap-2 d-flex justify-content-center">
                                    <button onClick={() => instanceAction('start', false)} className="bootOption text-white"><FontAwesomeIcon icon={faPlay} /></button>
                                    <button onClick={() => instanceAction('reboot', false)} className="bootOption text-white"><FontAwesomeIcon icon={faArrowsRotate} /></button>
                                    <button onClick={() => instanceAction('stop', false)} className="bootOption text-white"><FontAwesomeIcon icon={faStop} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row mt-4 mb-0">
                    <div className="col-12 col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title">Server</div>
                                <ul className="serversMenu p-0">
                                    <li className="list-item"><a onClick={() => menuChange('default')} href="javascript:void(0)" className="nav-link">Overview</a></li>
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('backups')} href="javascript:void(0)" className="nav-link">Backups</a></li> */}
                                    {/* <li className="list-item my-2 p-1"><a onClick={() => menuChange('firewall')} href="javascript:void(0)" className="nav-link">Firewall</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div className="card" hidden={cloudDetailsBlock}>
                            {cloud && (
                                <div className="p-3">
                                    <h5>Overview</h5>
                                    <ul>
                                        <li><strong>Hostname:</strong> {cloud.hostname}</li>
                                        <li><strong>Region:</strong> {cloud.region}</li>
                                        <li><strong>Status:</strong> {cloud.status}</li>
                                        <li><strong>IP Address:</strong> {cloud.ip}</li>
                                        <li><strong>CPU Usage:</strong> {(vmInfo.cpu * 100).toFixed(2)}%</li>
                                        <li><strong>Memory Usage:</strong> {(vmInfo.mem / 1024 / 1024).toFixed(2)} MB / {(vmInfo.maxmem / 1024 / 1024).toFixed(2)} MB</li>
                                        <li><strong>Disk Read:</strong> {(vmInfo.diskread / 1024 / 1024).toFixed(2)} MB</li>
                                        <li><strong>Disk Write:</strong> {(vmInfo.diskwrite / 1024 / 1024).toFixed(2)} MB</li>
                                        <li><strong>Network In:</strong> {(vmInfo.netin / 1024 / 1024).toFixed(2)} MB</li>
                                        <li><strong>Network Out:</strong> {(vmInfo.netout / 1024 / 1024).toFixed(2)} MB</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="card" hidden={backupBlock}>
                            {/* Backup details go here */}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal isOpen={showConfirmation} type="instanceAction" onHide={hideConfirmationBox} onConfirm={() => instanceAction(actionType.toString(), true)} />
        </div>
    );
};
