import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import "./HostingTable.css";
import "./Table.css";
import TableFooter from "./TableFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";

const HostingTable = ({ data, rowsPerPage, refreshFunction, pagination = true, disableHeader = false }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);


    return (
        <div className="HostingTable">
            {disableHeader != true &&
                <div className="row justify-content-start d-flex">
                     <div className="col-1 d-md-block d-none">
                        <p className="fw-bold">Status</p>
                    </div>
                    <div className="col-4 d-md-block d-none">
                        <p className="fw-bold">Label</p>
                    </div>
                    <div className="col-4 d-md-block d-none">
                        <p className="fw-bold">Domain</p>
                    </div>
                    <div className="col-1 d-md-block d-none">
                       
                    </div>
                   
                </div>
            }

            {slice.map((hosting) => (
                <div onClick={() => window.location.assign(hosting.status === 'cancelled' ? '# ' : '/products/hosting/' + hosting.id)} key={hosting.id} className="row serverRow justify-content-start d-flex my-2 p-3">
                   
                   <div className="col-1">
                        {hosting.status === 'active' && <Badge bg="success"> </Badge>}
                        {hosting.status === 'suspended' && <Badge bg="secondary"> </Badge>}
                        {hosting.status === 'cancelled' && <Badge bg="secondary"> </Badge>}
                    </div>
                     <div className="col-4">
                        <p className="m-0 p-0">{hosting.label}</p>
                        <small className="m-0 p-0 d-none d-sm-flex">{hosting.domain}</small>
                    </div>
                    <div className="col-5 ">
                        <p>{hosting.domain}</p>
                    </div>
                    
                    <div className="col-1 d-none d-sm-block justify-content-end d-flex">
                        <p className="mt-1"><FontAwesomeIcon icon={faArrowRight} /></p>
                    </div>
                </div>
            ))}

            {pagination && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}

        </div>

    );
};

export default HostingTable;